/* src/styles/App.css */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  @apply bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100;
}
