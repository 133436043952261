/* src/styles/global.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Root Variables */
:root {
  /* Color palette */
  --primary-color: #4297A0;
  --primary-dark: #357B83;
  --secondary-color: #F4EAE6;
  --text-color: #333333;
  --background-color: #FFFFFF;
  --error-color: #FF6B6B;

  /* Typography */
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-size-small: 0.875rem;
  --font-size-normal: 1rem;
  --font-size-large: 1.25rem;
  --font-size-xl: 1.5rem;

  /* Spacing */
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 1.5rem;

  /* Border */
  --border-radius: 0.75rem;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Custom utility classes */
.text-error {
  color: var(--error-color);
}

/* Gradient background */
.bg-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #93C5FD;
  --tw-gradient-via: #D8B4FE;
  --tw-gradient-to: #FBCFE8;
}

/* Card styles */
.card {
  @apply bg-white rounded-xl shadow-lg overflow-hidden;
}

/* Button styles */
.btn {
  @apply font-bold py-2 px-4 rounded-full transition duration-300;
}

.btn-primary {
  @apply bg-primary text-white hover:bg-primary-dark;
}

/* Animations */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}
